
import { computed, defineComponent } from 'vue'
import { getCountryName } from '@/core/helpers/assets'
import { Company } from '@/store/modules/CompanyModule'

export default defineComponent({
  name: 'kt-summary',
  components: {},
  emit: ['submit'],
  props: {
    loading: { type: Boolean, default: false },
    targetData: { type: Object },
    trialDays: { type: Number }
  },
  setup (props, { emit }) {
    const onSubmit = () => {
      emit('submit')
    }
    const details = computed(() => props.targetData) as unknown as Company
    return {
      details,
      getCountryName,
      onSubmit
    }
  }
})
